@import '~antd/dist/antd.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
    background: white;
    height: 130px;
    border-bottom: solid 1px #aea5a5;
    justify-content: center;
}

.ant-layout-header {
    line-height: 20px;
}

.ant-layout-header .ant-menu {
    line-height: 46px;
}

.ant-menu-horizontal {
    border: none;
}

.ant-menu-horizontal:not(.ant-menu-dark),
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: #0b543e;
    font-weight: bold;
    font-size: 16px;
}

.header-title {
    font-size: 38px !important;
    font-weight: bold;
}

.auth-form {
    padding-top: 2em;
    width: 35%;
}

.webkit-center {
    text-align: -webkit-center;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.auth-link {
    color: #0b543e !important;
}

.auth-link:hover {
    color: #0b543ecc !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom-color: #ef976e;
}
.logo {
    font-size: 54px;
}

.content-layout {
    min-height: 40vh;
    height: fit-content;
}

.sider {
    .ant-layout-sider-children {
        padding-top: 2em;
    }

    min-width: 20% !important;
    padding: 4px;
    background: white;

    .row {
        justify-content: center;
        color: #0b543e;
    }
    .link.row:hover {
        justify-content: center;
        color: #0b543ecc;
    }

    .name {
        font-size: 24px;
        font-weight: bold;
        text-decoration: underline;
        text-align: center;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

    .text {
        font-size: 20px;
        font-weight: bold;
    }
}

.content {
    background: white;
    padding: 3em;
}

.white-background {
    background: white;
}

.restricted-width {
    width: 85%;
}

.card-no-padding {
    .ant-card-body {
        padding: 0
    }
}

.ant-table-thead > tr > th {
    background: #0b543e;
    color: white;
    font-size: 18px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    border: none;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item-active:hover {
    border-color: #ef976e;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
    color: #ef976e;
}

.ant-pagination-prev:hover .ant-pagination-item-link:hover,
.ant-pagination-next:hover .ant-pagination-item-link:hover {
    border-color: #ef976e;
    color: #ef976e;
}

.display-flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.hoverable-icon:hover {
    color: #ef976e;
    cursor: pointer;
}

.ant-pagination-disabled .ant-pagination-item-link:hover {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    font-weight: bold;
    font-size: 16px;
}

.black {
    color: black;
}

.green {
    color: #0b543e;
}

.orange {
    color: #ef976e;
}

.red {
    color: red;
}

.icon-outline {
    border-radius: 50px;
    padding: 8px;
    border: solid 3px #ef976e;
}

.large-icon {
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

.card {
    .ant-card-head {
        background-color: #0b543e;
        color: white;
        font-size: 20px;
    }

    .ant-card-grid {
        width: 100%;
    }
}

.card-body-center {
    .ant-card-body {
        text-align: -webkit-center;
    }
}

.bordered-card {
    border: solid 1px #262626;
}

.logo {
    height: 110px;
}

.message-title {
    font-size: 22px;
    font-weight: bold;
}

.message-description {
    font-size: 16px;
    font-weight: bold;
    max-width: 100%;
}

.documents-list {
    font-size: 20px;
    font-weight: bold;
}

.brand {
    font-size: 18px;
}

.brand-meta {
    font-size: 10px;
}

.ant-checkbox-inner {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    border: solid 3px #ef976e;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ef976e;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ef976e;
    border: #ef976e;
}

.ant-btn-primary {
    background-color: #ef976e;
    border: #ef976e;
    border-radius: 10px;
    height: 40px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: bold;
    font-size: 16px;
}

.ant-btn-primary:hover {
    background-color: #ef976e;
}

.ant-btn-primary:focus {
    background-color: #ef976e;
}

.green-btn {
    .ant-btn-primary {
        background-color: #0b543e;
        border: #0b543e;
        border-radius: 10px;
        height: 40px;
        padding-left: 24px;
        padding-right: 24px;
        font-weight: bold;
        font-size: 16px;
    }

    .ant-btn-primary:hover {
        background-color: #0b543e;
    }

    .ant-btn-primary:focus {
        background-color: #0b543e;
    }
}

.small-btn {
    .ant-btn-primary {
        height: 34px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
    }
}

.display-block {
    display: block;
}

.align-self-center {
    align-self: center;
}

.padding-xs {
    padding: 0.25em;
}

.padding-sm {
    padding: 0.5em;
}

.padding-md {
    padding: 1em;
}

.padding-lg {
    padding: 1.5em;
}

.padding-xl {
    padding: 3em;
}

.padding-y-xs {
    padding: 0.25em 0;
}

.padding-y-sm {
    padding: 0.5em 0;
}

.padding-y-md {
    padding: 1em 0;
}

.padding-y-lg {
    padding: 1.5em 0;
}

.padding-y-xl {
    padding: 3em 0;
}

.padding-x-xs {
    padding: 0 0.25em;
}

.padding-x-sm {
    padding: 0 0.5em;
}

.padding-x-md {
    padding: 0 1em;
}

.padding-x-lg {
    padding: 0 1.5em;
}

.padding-x-xl {
    padding: 0 3em;
}

.padding-top-xs {
    padding-top: 0.25em;
}

.padding-top-sm {
    padding-top: 0.5em;
}

.padding-top-md {
    padding-top: 1em;
}

.padding-top-lg {
    padding-top: 1.5em;
}

.padding-top-xl {
    padding-top: 3em;
}

.padding-right-xs {
    padding-right: 0.25em;
}

.padding-right-sm {
    padding-right: 0.5em;
}

.padding-right-md {
    padding-right: 1em;
}

.padding-right-lg {
    padding-right: 1.5em;
}

.padding-right-xl {
    padding-right: 3em;
}

.padding-bottom-xs {
    padding-bottom: 0.25em;
}

.padding-bottom-sm {
    padding-bottom: 0.5em;
}

.padding-bottom-md {
    padding-bottom: 1em;
}

.padding-bottom-lg {
    padding-bottom: 1.5em;
}

.padding-bottom-xl {
    padding-bottom: 3em;
}

.padding-left-xs {
    padding-left: 0.25em;
}

.padding-left-sm {
    padding-left: 0.5em;
}

.padding-left-md {
    padding-left: 1em;
}

.padding-left-lg {
    padding-left: 1.5em;
}

.padding-left-xl {
    padding-left: 3em;
}

.padding-left-xxl {
    padding-left: 4em;
}

.margin-xs {
    margin: 0.25em;
}

.margin-sm {
    margin: 0.5em;
}

.margin-md {
    margin: 1em;
}

.margin-lg {
    margin: 1.5em;
}

.margin-xl {
    margin: 3em;
}

.margin-y-xs {
    margin: 0.25em 0;
}

.margin-y-sm {
    margin: 0.5em 0;
}

.margin-y-md {
    margin: 1em 0;
}

.margin-y-lg {
    margin: 1.5em 0;
}

.margin-y-xl {
    margin: 3em 0;
}

.margin-x-xs {
    margin: 0 0.25em;
}

.margin-x-sm {
    margin: 0 0.5em;
}

.margin-x-md {
    margin: 0 1em;
}

.margin-x-lg {
    margin: 0 1.5em;
}

.margin-x-xl {
    margin: 0 3em;
}

.margin-top-xs {
    margin-top: 0.25em;
}

.margin-top-sm {
    margin-top: 0.5em;
}

.margin-top-md {
    margin-top: 1em;
}

.margin-top-lg {
    margin-top: 1.5em;
}

.margin-top-xl {
    margin-top: 3em;
}

.margin-right-xs {
    margin-right: 0.25em;
}

.margin-right-sm {
    margin-right: 0.5em;
}

.margin-right-md {
    margin-right: 1em;
}

.margin-right-lg {
    margin-right: 1.5em;
}

.margin-right-xl {
    margin-right: 3em;
}

.margin-bottom-xs {
    margin-bottom: 0.25em;
}

.margin-bottom-sm {
    margin-bottom: 0.5em;
}

.margin-bottom-md {
    margin-bottom: 1em;
}

.margin-bottom-lg {
    margin-bottom: 1.5em;
}

.margin-bottom-xl {
    margin-bottom: 3em;
}

.margin-left-xs {
    margin-left: 0.25em;
}

.margin-left-sm {
    margin-left: 0.5em;
}

.margin-left-md {
    margin-left: 1em;
}

.margin-left-lg {
    margin-left: 1.5em;
}

.margin-left-xl {
    margin-left: 3em;
}

.zero-margin {
    margin: 0;
}

.full-width {
    width: 100%;
}

.float-right {
    float: right;
}
.float-left {
    float: left;
}

.align-center {
    text-align: center;
}
.align-left {
    text-align: left;
}
.align-right {
    text-align: right;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0 2px;
}

.card-container p {
    margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: #fff;
    border-color: #262626;
    font-weight: bold;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #0b543e;
    border-color: #262626;
    color: white;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #262626;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ant-tabs-tab:hover {
    color: #ef976e;
}
.ant-tabs-tab:active {
    color: #ef976e;
}

.cross-icon {
    font-size: 30px;
}

.space-between {
    justify-content: space-between;
}

.font-big {
    font-size: 18px;
}
.h-100 {
    height: 100%;
}
.h-inherit {
    height: inherit;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: solid 2px #0b543e;
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: solid 2px #0b543e;
    box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: solid 2px gray;
    border-radius: 8px;
    height: fit-content;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 12px;
    font-size: 16px;
    text-align: left;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
    border: solid 2px gray;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
}

.ant-input {
    border: solid 2px gray;
    border-radius: 8px;
    padding: 14px;
    font-size: 16px;
}

.ant-input-affix-wrapper {
    border: solid 2px gray;
    border-radius: 8px;
    padding: 14px;
    font-size: 16px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: solid 2px #0b543e;
    box-shadow: none;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input:hover {
    border: solid 2px #0b543e;
    box-shadow: none;
}
.ant-input:hover {
    border: solid 2px #0b543e;
    border-radius: 8px;
}

.full-width {
    width: 100%;
}
.text-normal {
    font-weight: normal;
}
.text-400 {
    font-weight: 500;
}
.half-width {
    width: 50%;
}

.ant-upload.ant-upload-drag {
    border: none;
    background-color: transparent;
    text-align: -webkit-center;
}

.ant-layout-footer {
    background-color: white;
    padding: 3em 0 0;
}

.footer-text {
    color: white !important;
    font-size: 16px;
}

.footer-menu {
    color: white !important;
    font-size: 18px;
    font-weight: bold;
}

.dark-bg {
    background-color: #262626;
}

.footer-icon {
    color: white;
    font-size: 42px;
    margin-right: 0.5em;
}

.footer-row {
    border-bottom: solid 1px white;
}

.footer-top {
    padding-right: 6em;
    padding-left: 6em;
}

.footer-top-div {
    height: 15vh;
    text-align: -webkit-center;
    width: 100%;
    padding: 2em;
    color: white;
    background-color: #ef976e;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.footer-email {
    margin-top: 2em;
    height: 8vh;
    text-align: -webkit-center;
    width: 100%;
    padding-left: 18em;
    padding-right: 18em;

    .ant-input-affix-wrapper {
        border: none;
        border-radius: 30px;
        box-shadow: none;
        font-size: 16px;
    }

    .ant-input-affix-wrapper:hover {
        border: none;
    }
}

.footer-email-field {
    background-color: white;
    width: 100%;
    border-radius: 40px;
    height: 5vh;
    padding-left: 1em;
    padding-right: 1em;
}

.panel-text {
    font-size: 16px;
    font-weight: bold;
    color: #485460;
}

.collapse-header-text {
    font-size: 20px;
    font-weight: bold;
    color: #485460;
}
.ant-collapse-item-active .anticon-right-circle {
    transform: rotate(90deg);
}

.ant-collapse-borderless {
    background: white;
}

.ant-form-item-explain.ant-form-item-explain-error {
    text-align: left;
    margin-bottom: 4px;
}

.selectable {
    cursor: pointer;
}

// messageList styling
.user-message {
    border: 1px solid #bba5a5;
    border-radius: 11px;
    padding-inline: 10px;
    padding-block: 5px;
}
.self {
    background: #f5f9f5;
}
.others {
    background: #ffefe8;
}

.each-message-other {
}
.sender {
    display: flex;
    row-gap: 0px;
    flex-direction: row-reverse;
}
.sender_message .message-title {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    align-items: flex-end;
}
.sender_align {
    display: flex;
    justify-content: flex-end;
}
.padding-self {
    padding-right: 0.5em;
}
.padding-other {
    padding-left: 0.5em;
}
.send__new_msg {
    margin-top: 30px;
    width: 100%;
    height: 45px;
    position: relative;
    z-index: 99000;
    display: flex;
    justify-content: space-between;
}
.send__new_msg input {
    border-radius: 1px;
    border: 1px solid gray;
    height: inherit;
}
.send__new_msg_outer {
    position: absolute;
    bottom: 0;
}
.msg-area {
    width: 100%;
    margin-bottom: 75px;
    // overflow: auto;
    max-height: 55vh;
    scroll-snap-align: center;
    // overflow-y: auto;
    // overscroll-behavior-y: contain;
    // scroll-snap-type: y proximity;
    padding-block: 3px;
}

.send__new_input {
    width: 70%;
    padding-inline: 12px;
    font-size: 17px;
    border: 0px;
    flex: 1;
}
.send__new_input:focus {
    outline: none;
}

// .scrollable_style {
// }
.scrollable_style::-webkit-scrollbar {
    width: 9.5px; /* width of the entire scrollbar */
}

.scrollable_style::-webkit-scrollbar-track {
    background: #dfe9e6; /* color of the tracking area */
}

.scrollable_style::-webkit-scrollbar-thumb {
    background-color: #ffefe8;
    border-radius: 5px;
    border: 2.4px solid #dfe9e6; /* creates padding around scroll thumb */
}

.send_btn {
    position: relative;
    height: inherit;
}
.send_btn button {
    height: inherit;
}

.ant-picker {
    border: solid 2px gray;
    border-radius: 8px;
    padding: 14px;
}

.ant-picker-input > input {
    font-weight: bold;
    font-size: 16px;
}

.ant-picker:hover {
    border: solid 2px #0b543e;
    border-radius: 8px;
}
.chat-header {
    margin-bottom: 12px;
    margin-inline: -10px;
    margin-top: -15px;
    /* border: 2px solid gray; */
    background: #0b543e21;
}
.minimize-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    width: 100%;
}
.cover-rest-width {
    display: flex;
    height: inherit;
    flex: auto;
}
@media (max-width: 480px) {
    .padding-left-xl{
        padding-left: unset !important;
    }
    .logo{
        width: 150px;
    }
    .ant-layout-has-sider {
      min-width: 400px !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
    .ant-layout-content{
        min-width: 420px !important;
        .restricted-width{
            min-width: 400px !important;
        }
    }
    .ant-row.message-title{
        font-size: 1rem;
        padding: 5px;
    }
    .ant-row.message-description{
        font-size: .85rem;
        font-weight: unset !important;
    }
    .large-icon{
        position: relative;
        left:-20px
    }
    .show-on-mobile-only{
        display: flex !important;
    }
    .logout-btn-header{
        display: none !important;
    }
    .top-text-auth-header{
        font-size: 2em !important;
    }
    .no-padding-mobile{
        margin-top: 1em !important;
        padding: 1em !important;
    }
    .page-title-auth-header{
        font-size: 2.5em !important;
    }
    .auth-form{
        min-width: 320px !important;
    }
    .min-width-mobile-320{
        width: auto !important;
        min-width: 320px !important;
    }
}

.show-on-mobile-only{
     display: none;
}